<template>

    <div v-if="stepVisible" class="offer__modal-step-wrapper">
        <ModalWrapper :stepValid="stepValid"
                      :hasBackBtn="true"
                      :title="'Kontrollera dina uppgifter'"
                      :nextLabel="'Skicka in och boka'"
                      :isConfirmStep="true"
                      :price="(price.isPriceRequest && price.priceLoaded && !price.priceError) && price.totalPrice || 0"
                      @onNextStep="submitOffer"
                      @onPrevStep="gotoPrevStep">

            <img src="../assets/AX-Spinner-2.gif" class="spinner-loading" v-if="loading" />

            <div class="offer__modal-container offer__price-display" v-bind:class="{ notvisible: loading }">

                <div v-if="price.isPriceRequest && price.priceError && !hidePrice" class="offer__user-form__error">
                    <div style="font-weight:500;">
                        {{texts.priceErrorText}}
                    </div>
                </div>

                <div class="offer__price-display__section-header">
                    Dina val
                </div>

                <div class="offer__price-display__list-section">
                    <div class="offer__price-display__field offer__price-display__field--50">
                        <div class="offer__price-display__field-header">
                            <h4>Regnr</h4>
                        </div>
                        <span style="text-transform:uppercase">{{car.data.regNo}}</span>
                    </div>

                    <div class="offer__price-display__field offer__price-display__field--50">
                        <div class="offer__price-display__field-header">
                            <h4>Tjänst</h4>
                            <a href="#" class="link-btn" @click="gotoServiceStep()">Ändra</a>
                        </div>
                        <div v-for="(item, index) in servicesSummary" v-bind:key="'sumitem_' + index">
                            {{item}}
                        </div>
                    </div>

                    <div class="offer__price-display__field offer__price-display__field--50">
                        <div class="offer__price-display__field-header">
                            <h4>Verkstad</h4>
                        </div>
                        {{workshop.title}}<br />
                        {{workshop.address}} {{workshop.city}}
                    </div>

                    <div class="offer__price-display__field offer__price-display__field--50" v-if="price.selectedDate != null">
                        <div class="offer__price-display__field-header">
                            <h4>Datum och tid</h4>
                            <a href="#" class="link-btn" @click="gotoWorkshopStep()">Ändra</a>
                        </div>
                        {{toDateString(price.selectedDate, price.selectedTime)}}<br />
                    </div>

                    <div v-if="price.isPriceRequest && price.priceLoaded && !price.priceError && !hidePrice" style="width:100%">
                        <div class="offer__price-display__section-header">
                            Kostnader
                        </div>
                        <div class="offer__price-display__section">
                            <PriceDetailsConfirm />
                        </div>
                    </div>

                    <div class="offer__price-display__field offer__price-display__field--100" v-if="!hidePrice">
                        <div class="offer__price-display__field-header faq-toogle-up" v-on:click="toogleFaq">
                            <h4 v-if="price.isPriceRequest && price.priceLoaded && !price.priceError">{{texts.infoOfferWithPriceHeader}}</h4>
                            <h4 v-else>{{texts.infoOfferHeader}}</h4>
                            <i v-if="faqClosed" class="fa fa-caret-down"></i>
                        </div>
                        <div class="faq-toogle-down" v-if="price.isPriceRequest && price.priceLoaded && !price.priceError && !faqClosed"
                             v-html="infoOfferWithPrice">
                        </div>
                        <div class="faq-toogle-down" v-else-if="!faqClosed"
                             v-html="infoOffer">
                        </div>
                        <a href="#" class="link-btn" v-if="!faqClosed" v-on:click="toogleFaq">
                            Stäng
                        </a>
                    </div>

                    <div v-if="error" style="color:red; margin-bottom:24px;">
                        Någonting gick fel, var god försök senare.
                    </div>

                    <div style="height:40px" class="hidden visible-xs"></div>

                </div>

            </div>
        </ModalWrapper>
    </div>

</template>

<script>

    import axios from 'axios';
    import { mapState, mapActions, mapGetters } from 'vuex';
    import steps from '../constants/steps';
    import states from '../constants/RequestStates'
    import localeDateString from '../store/utils/localeDateString';
    import ModalWrapper from './ModalWrapper.vue';
    import PriceDetailsConfirm from './PriceDetailsConfirm.vue';

    export default {
        name: 'confirmStep',
        components: {
            ModalWrapper,
            PriceDetailsConfirm
        },
        data() {
            return {
                termsPolicyTerms: false,
                params: null,
                error: false,
                loading: false,
                faqClosed: true,
            };
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                services: state => state.selectedServices,
                car: state => state.car.pickCarState,
                user: state => state.user,
                workshop: state => state.workshop.selectedWorkshop.workshop,
                miles: state => state.car.miles,
                selectedService: state => state.car.selectedService,
                price: state => state.price,
                comments: state => state.comments,
                texts: state => state.config.texts,
                priceServices: state => state.mainServices.priceApiServices,
                offerType: state => state.config.offerType,
                hidePrice: state => state.config.disablePrices,
                allwaysUseDates: state => state.config.allwaysAllowBooking,
                priceApiVersion: state => state.price.priceApiVersion,
            }),
            servicesSummary: {
                get() {
                    let titles = [];    
                    this.services.items.forEach((s,) => {
                        let summaryItem = s.Name;
                        let comment = this.getCommentById(s.Id);
                        if (comment.length) {
                            if (comment.length > 40)
                                comment = comment.substring(0, 38) + '..';
                            summaryItem += " - " + comment;
                        }

                        titles.push(summaryItem);
                    })
                    return titles;
                },
            },
            stepVisible: {
                get() {
                    return (this.stepProgress.currentStep == steps.confirmStep);
                },
            },
            stepValid: {
                get() {
                    return this.user.input.termsPolicyTerms && !this.loading;
                }
            },
            infoOffer: {
                get() {
                    return document.getElementById('infoOffer').innerHTML;
                }
            },
            infoOfferWithPrice: {
                get() {
                    return document.getElementById('infoOfferWithPrice').innerHTML;
                }
            },
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
            }),
            ...mapGetters({
                totalPrice: 'price/totalPrice',
            }),
            setTermsPolicyTerms(value) {
                this.termsPolicyTerms = value;
            },
            gotoNextStep() {
                this.switchStep(steps.thanksStep);
            },
            gotoPrevStep() {
                this.switchStep(steps.userFormStep);
            },
            gotoServiceStep() {
                this.switchStep(steps.serviceStartStep);
            },
            gotoWorkshopStep() {
                this.switchStep(steps.workshopStep);
            },
            isServiceApiPrice() {
                if (this.price.servicePriceState.data != null && this.price.servicePriceState.data.priceInfo != null && this.isTotalPriceValid())
                    return this.price.servicePriceState.status == states.Success;
                return false;
            },
            isTotalPriceValid() {
                return this.price.totalPrice > 0 && this.price.priceError == false;
            },
            toDateString(date, time) {
                return localeDateString(date, time, 'long');
            },
            async submitOffer() {
                this.loading = true;
                this.error = false;
                const token = document.getElementById('offerForm').querySelector('input[name=__RequestVerificationToken]').getAttribute('value');
                this.params = this.getOfferParams();
                await axios
                    .post(`/api/applications/offers/v2/submitFixedPriceOffer`, 
                        this.params,
                        {
                            headers: { __RequestVerificationToken: token }
                        })
                    .then(x => {
                        if (x.data.success) {
                            this.setTermsPolicyTerms = false;
                            this.gotoNextStep();
                            this.trackOffer(x.data.offertId);
                        } else {
                            this.error = true;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.error(error);
                        this.error = true;
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            getOfferParams() {
                return {
                    WorkshopId: this.workshop.id,
                    Services: this.services.ids,
                    RegNo: this.car.data.regNo,
                    Miles: this.miles,
                    Age: this.car.data.age,
                    SelectedService: this.selectedService,
                    PriceIsValid: this.isServiceApiPrice() ? this.price.servicePriceState.data.priceInfo.isValid : false,
                    Price: this.isServiceApiPrice() ? this.price.servicePriceState.data.priceInfo.totalCost : 0,
                    FirstName: this.user.input.firstname,
                    LastName: this.user.input.lastname,
                    Phone: this.user.input.phone,
                    Company: this.user.input.company,
                    Email: this.user.input.email,
                    DiscountCode: '',
                    Message: this.user.input.message + this.getComments(),
                    TermsOk: this.user.input.termsPolicyTerms,
                    newsletterOk: this.user.input.newsletterChecked,
                    SelectedDate: (this.isTotalPriceValid() || this.allwaysUseDates) ? this.price.selectedDate : null,
                    Version: this.priceApiVersion != null ? this.priceApiVersion : '',
                    OfferType: this.offerType,
                    HidePrice: this.hidePrice,
                    SimplePriceInfos: this.getSimpleServices(),
                    PriceError: this.price.priceError,
                    PricePageId: this.isServiceApiPrice() ? this.price.servicePriceState.data.priceInfo.pricePageId : null,
                };
            },
            getComments() {
                let text = '\n\n';
                this.comments.commentMainItems.forEach(c => {
                    if (c.text.length)
                        text += c.text + '\n\n';
                });
                this.comments.commentItems.forEach(ci => {
                    ci.forEach(c => {
                        text += c.text + '\n\n';
                    });
                });
                return text;
            },
            getCommentById(id) {
                let text = '';
                this.comments.commentMainItems.forEach(c => {
                    if (id == c.id && c.text.length)
                        text = c.text;
                });
                this.comments.commentItems.forEach(ci => {
                    ci.forEach(c => {
                        if (id == c.id && c.text.length)
                            text = c.text;
                    });
                });
                return text;
            },
            getSimpleServices() {
                var result = [];
                if (this.price.servicePriceState.data != null) {
                    this.price.servicePriceState.data.simplePriceInfos.forEach(x => {
                        result.push({
                            IsValid: x.isValid,
                            ServiceId: x.serviceId,
                            Price: x.sumTotal,
                            Version: x.version,
                            ServiceName: x.serviceName
                        });
                    });
                }
                return result;
            },
            trackOffer(offerid) {

                let offerData = { OffertId: offerid}
                let allData = {
                    ...this.params,
                    ...offerData
                }; 

                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewSubmitOfferSuccess',
                        data: allData,
                    });

                try {
                    window.doCommonLeadAction(offerid, 'Offer');
                } catch {
                    // eslint-disable-next-line no-console
                    console.log('Error in ')
                }

            },
            toogleFaq() {
                this.faqClosed = !this.faqClosed;
            }
        },
    }


</script>

<style lang="scss" scoped>
    .notvisible {
        visibility: hidden;
    }
    .spinner-loading {
        position: absolute;
        margin: 10px auto;
        width: 56px;
        left: calc(50% - 28px);
    }
    #mobile-checkbox {
        display: none;
    }
    @media (max-width: 767px) {
        #mobile-checkbox {
            display: block;
            position: fixed;
            z-index: 10;
            bottom: 93px;
            z-index: 1200;
            padding: 16px 24px 0;
            background: white;
            font-size: 12px;
            font-weight: 500;
            right: 0;
            left: 0;
            box-shadow: 0 -2px 8px rgb(0 0 0 / 4%);
            border-top: 1px solid #EBEDEE;
        }
    }

    @media (max-width: 320px) {
        #mobile-checkbox {
            bottom: 80px;
        }
    }

    .termslink-toggle {
        display: contents;
        color: #0845F4;
        font-weight: 500;
        text-decoration: underline;
    }

    .faq-toogle-up h4, .faq-toogle-up i {
        cursor: pointer;
    }

</style>