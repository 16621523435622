<template>
    <div v-if="stepVisible" class="offer__modal-step-wrapper">
        <ModalWrapper :stepValid="stepValid" :title="title" :hasBackBtn="true" @onNextStep="gotoNextStep" @onPrevStep="gotoPrevStep">
   
            <div class="offer__modal-container">
                <template v-for="(i, index) in mainServices.indexs">

                    <div v-bind:key="i" v-if="index == currentIndex">

                        <ul>
                            <li v-for="(service, index2) in services[i].Items" v-bind:key="service.Id" class="offer__select-section">
                                <div v-if="service.SubServiceParentId == 0" class="offer__select-row">

                                    <!--<span v-if="isEnabled(service)">Enabled</span>-->
                                    <div class="offer__select-row__content">
                                        <div class="offer__select-row__title">
                                            {{service.Name}}
                                            <span class="offer__select-row__flag" v-if="isPriceAvailable(service) && !hidePrice">Pris direkt</span>
                                            <span class="offer__select-row__flag discount" v-if="isDiscountAvailable(service) && !hidePrice">{{service.DiscountOfferText}}</span>
                                        </div>
                                        <div>
                                            <a href="#"
                                               v-bind:id="'more-text-service-2' + index2"
                                               v-on:click="showServiceText(index2)"
                                               class="offer__select-row__link">Läs mer</a>
                                        </div>
                                        <div style="display:none">
                                            <div v-html="service.Description">
                                            </div>
                                            <a href="#"
                                               v-bind:id="'less-text-service-2' + index2"
                                               v-on:click="hideServiceText(index2)"
                                               class="offer__select-row__link">Läs mindre</a>
                                        </div>
                                        <div class="mt-12" v-if="service.UseSpecificMessageField && selectedServices.includes(service.Id)">
                                            <input class="input-field-sm" type="text" placeholder="Beskriv vad du vill ha hjälp med..." v-model="commentsData[index][index2].text" v-bind:id="'comment-service' + service.Id" />
                                        </div>
                                    </div>

                                    <div class="offer__select-row__action-container">
                                        <div class="btn btn--icon"
                                             :class="{
                                            'btn--success': selectedServices.includes(service.Id),
                                             'disabled': isEnabled(service) == false
                                        }"
                                             @click="setSelected(service.Id)">
                                            {{ selectedServices.includes(service.Id) ? '' : 'Välj' }}
                                            <i v-if="selectedServices.includes(service.Id)" class="fas fa-check btn__solo-icon"></i>
                                        </div>
                                    </div>

                                </div>
                            </li>
                        </ul>

                    </div>

                </template>
            </div>

        </ModalWrapper>
    </div>

</template>

<script>

    import { mapState, mapActions } from 'vuex';
    import steps from '../constants/steps';
    import ModalWrapper from './ModalWrapper.vue';

    export default {
        components: { ModalWrapper },
        name: 'servicesStep',
        data() {
            return {
                currentIndex: 0,
                allServices: [],
                internalStepDirectionNext: true,
                title: '',
                commentsData: []
            };
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                services: state => state.config.services,
                mainServices: state => state.mainServices,
                mainServicesIds: state => state.mainServices.indexs,
                selected: state => state.selectedServices.ids,
                preselectedLoad: state => state.selectedServices.preselectedLoad,
                preSelectedWorkhopServices: state => state.preselectedWorkshop.services.data,
                preSelectedComment: state => state.config.preselected.comment,
                preSelectedServices: state => state.config.preselected.serviceIds,
                commentItems: state => state.comments.commentItems,
                selectedObjects: state => state.selectedServices.items,
                hidePrice: state => state.config.disablePrices,
            }),
            stepVisible: {
                get() {
                    return (this.stepProgress.currentStep == steps.servicesStep);
                },
            },
           selectedServices: {
                get() {
                   return this.selected
                },
               set(value) { 
                   let services = this.getServices(value);
                   this.updateServices({ ids: value, items: services, preselectedLoad: false });
                }
            }, 
            stepValid: {
                get() {
                    if (this.isLastInternalStep()) {
                        return this.selectedServices.length > 0;
                    }
                    return true;
                }
            },
            comments: {
                get() {
                    return this.commentItems
                },
                set(value) {
                    this.$store.commit('comments/updateComments', value);
                }
            }
        },
        watch: {
            currentIndex() {
                this.checkSingleService();
                this.updateTitle();
            },
            stepVisible(visible) {
                if (visible) {
                    if (this.preselectedLoad) {
                        this.selectedServices = this.selected;
                    }
                    this.checkSingleService();
                    this.updateTitle();
                }
            },
            mainServicesIds() {
                this.intiComments()
            }
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
                updateServices: 'selectedServices/updateServices',
                resetPrice: 'price/resetPrice',
            }),
            intiComments() {
                this.commentsData = [];
                this.mainServicesIds.forEach((i) => {
                    let commentItems = [];
                    this.services[i].Items.forEach((item) => {
                        let textValue = '';
                        if (this.isPreselectedServices() && this.preSelectedComment.length) {
                            if (this.preSelectedServices == item.Id.toString()) {
                                textValue = this.preSelectedComment;
                            }
                        }
                        commentItems.push({ id: item.Id, text: textValue });
                    });
                    this.commentsData.push(commentItems);
                });
            },
            setSelected(value) {
                let values = [];
                if (this.selectedServices.includes(value)) {
                    values = this.selectedServices.filter(id => id !== value);
                } else {
                    values = [...this.selectedServices, value];
                }
                this.selectedServices = values;
                this.tryCommentFocus(value);
            },
            tryCommentFocus(id) {
                setTimeout(() => {
                    let commentField = document.getElementById('comment-service' + id);
                    if (commentField)
                        commentField.focus();
                }, 200);
            },
            isPreselectedServices() {
                return this.preSelectedServices != undefined && this.preSelectedServices != '';
            },
            checkSingleService() {
                const mainIndex = this.mainServices.indexs[this.currentIndex]
                const isSingleService = this.services[mainIndex].Items.length == 1;
                if (isSingleService) {
                    const singleServiceId = this.services[mainIndex].Items[0].Id;
                    const notSelected = !this.selectedServices.includes(singleServiceId);

                    if (notSelected)
                        this.autoSelectSingleService(singleServiceId);

                    if (this.internalStepDirectionNext)
                        this.gotoNextStep()
                    else
                        this.gotoPrevStep();
                }
            },
            autoSelectSingleService(singleServiceId) {
                let sClone = [...this.selectedServices];
                sClone.push(singleServiceId)
                this.selectedServices = sClone;
            },
            gotoNextStep() {

                if (this.isLastInternalStep()) {
                    this.internalStepDirectionNext = false;
                    this.resetPrice();
                    this.comments = this.commentsData;
                    this.switchStep(steps.carStep);
                    this.singleGotoNext = false;
                    this.trackServices();
                }
                else {
                    this.internalStepDirectionNext = true;
                    this.currentIndex++;
                }

                this.title = this.services[this.currentIndex].Headline
            },
            gotoPrevStep() {

                if (this.currentIndex == 0) {
                    this.internalStepDirectionNext = true;
                    this.singleGotoNext = true;
                    this.switchStep(steps.serviceStartStep);
                }
                else {
                    this.internalStepDirectionNext = false;
                    this.currentIndex--;
                }
            },
            isLastInternalStep() {
                return this.currentIndex + 1 == this.mainServices.indexs.length;
            },
            getServices(ids) {
                this.initAllServices();
                let hits = [];
                ids.forEach((id) => {
                    const found = this.allServices.find(s => s.Id == id);
                    hits.push(found)
                })
                return hits;
            }, 
            initAllServices() {
                if (this.allServices.length == 0) {
                    this.services.forEach((s) => {
                        this.allServices.push(...s.Items);
                    });
                }
            },
            showServiceText(index) {
                this.toggleParents('less-text-service-2' + index, 'more-text-service-2' + index);
            },
            hideServiceText(index) {
                this.toggleParents('more-text-service-2' + index, 'less-text-service-2' + index);
            },
            toggleParents(elIdShow, elIdHide) {
                document.getElementById(elIdShow).parentElement.setAttribute('style', '');
                document.getElementById(elIdHide).parentElement.setAttribute('style', 'display:none');
            },
            isPriceAvailable(service) {
                return service.IsStaticPriceAllowed || service.EnabledPriceApi;
            },
            isDiscountAvailable(service) {
                return (service.DiscountPercent != null);
            },
            isEnabled(serviceItem) {
                if (this.preSelectedWorkhopServices != null) {
                    return this.preSelectedWorkhopServices.map((i) => (i.serviceID)).includes(serviceItem.Id);
                }
                return true;
            },
            trackServices() {
                this.$store.commit('config/trackAction',
                    {
                        event: 'resetSelectedServicesObjects',
                        data: { selectedCar: this.selectedCar, selectedServicesObjects: null },
                    });
                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewPickServicesSuccess',
                        data: { selectedCar: this.selectedCar, selectedServicesObjects: this.selectedObjects  },
                    });
            },
            updateTitle() {
                const mainIndex = this.mainServices.indexs[this.currentIndex]
                const subTitle = this.services[mainIndex].HeadlineAlt;
                if (subTitle != null && subTitle.length > 0)
                    this.title = subTitle;
                else
                    this.title = this.services[mainIndex].Headline;
            }
        },
    }

</script>