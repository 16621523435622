<template>

    <div v-if="stepVisible" class="offer__modal-step-wrapper">
        <ModalWrapper :stepValid="stepValid" :title="'Fyll i din bils uppgifter'" :nextLabel="'Fortsätt'" :hasBackBtn="true" @onNextStep="gotoNextStep" @onPrevStep="gotoPrevStep">

            <div class="offer__modal-container">
                <div class="offer__input-section">
                    <div class="offer__input-label">
                        Ange regnummer:
                    </div>
                    <div class="offer__input-container offer__input-container--reg-nr">
                        <img src="../assets/regnr-2022.svg" />
                        <input id="offer-reg-number"
                               type="text"
                               required
                               minlength="6"
                               maxlength="7"
                               @keyup="regNoKeyup"
                               @change="regNoKeyup"
                               v-model="regno" />
                    </div>

                    <div class="offer__input-error" v-if="showRegError">
                        {{ cartTexts.carnotFound }}
                    </div>
                </div>

                <div class="offer__input-section">
                    <div class="offer__input-label">
                        {{ cartTexts.mileage }}:
                    </div>
                    <div class="offer__input-container">
                        <input id="offer-miles" type="number"
                               :required="useMilageValidation ? true : false"
                               max="500000"
                               min="0"
                               v-model="miles" @keyup="milesKeyUp" step="1" />
                    </div>

                    <div class="offer__input-error" v-if="showMileError">
                        Felaktigt miltal, ange heltal i mil.
                    </div>

                </div>

                <div class="offer__input-section" v-if="showServices">

                    <div class="offer__input-label">
                        Servicevariant
                        <div class="offer__input-helper">Osäker på vilket val du ska göra? Välj det översta.</div>
                    </div>

                    <div class="offer__input-container">
                        <select v-model="selectedService">
                            <option value="">Välj servicevariant</option>
                            <option v-bind:value="service.id" v-for="service in services" v-bind:key="service.id">
                                {{service.description}}
                            </option>
                        </select>
                    </div>

                </div>

                <div v-if="selectedCar != null" class="offer__selected-car">
                    {{selectedCar.name}}
                    {{selectedCar.formattedRegistrationDate}}
                </div>

            </div>
        </ModalWrapper>

    </div>

</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import steps from '../constants/steps';
    import ajaxHelper from '../store/utils/ajaxHelper';
    import states from '../constants/RequestStates'
    import ModalWrapper from './ModalWrapper.vue';

    export default {
        components: { ModalWrapper },
        name: 'carStep',
        data() {
            return {
                regno: '',
                regnoPrev: '',
                regnoPreselected: '',
                showServices: false,
                milesValid: false
            };
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                selectedCar: state => state.car.pickCarState.data,
                selectedCarStatus: state => state.car.pickCarState.status,
                milesState: state => state.car.miles,
                mainServices: state => state.mainServices,
                services: state => state.car.serviceTypesState.data,
                selectedServiceState: state => state.car.selectedService,
                cartTexts: state => state.config.texts.carform,
                preSelectedRegNo: state => state.config.preselected.registrationNumber,
                apiWorkshopId: state => state.config.texts.carform.fallbackWorkshopId
            }),
            ...mapGetters({
                defaultTimeout: 'config/defaultTimeout',
            }),
            stepVisible: {
                get() {
                    return (this.stepProgress.currentStep == steps.carStep);
                },
            },
            miles: {
                get() {
                    return this.milesState;
                },
                set(value) {
                    this.$store.commit('car/updateMilesState', value);
                }
            },
            selectedService: {
                get() {
                    return this.selectedServiceState;
                },
                set(value) {
                    this.$store.commit('car/updateSelectedService', value);
                }
            },
            stepValid: {
                get() {
                    if (this.selectedCar == null || !this.milesValid)
                        return false;
                    if (this.usePriceApi) {
                        if (this.services != null && this.selectedService.length == 0)
                            return false;
                        return this.miles != null && this.miles.length > 0;
                    }
                    else
                        return true;
                }
            },
            usePriceApi: {
                get() {
                    return this.mainServices.priceApiServices.length > 0;
                }
            },
            useMilageValidation: {
                get() {
                    return this.usePriceApi || this.mainServices.useMilageValidation;
                }
            },
            showRegError: {
                get() {
                    return this.selectedCarStatus == states.Failure;
                }
            },
            showMileError: {
                get() {
                    return this.miles != null && !this.milesValid;
                }
            }
        },
        watch: {
            usePriceApi(useApi) {
                if (useApi && this.selectedCar != null) {
                    var data = { RegNo: this.selectedCar.regNo };
                    this.ajaxPickCar.post(data, this.pickCarSuccess());
                }
            },
            stepVisible(value) {
                if (value) {
                    if (this.preSelectedRegNo.length && !this.regno.length) {
                        this.regno = this.preSelectedRegNo;
                        setTimeout(function () { this.regNoKeyup() }.bind(this), 100)
                    }
                    setTimeout(function () { document.getElementById('offer-reg-number').focus() }.bind(this), 100)
                }
            }
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
                pickCarState: 'car/pickCarState',
                updateMilesState: 'car/updateMilesState',
                serviceTypesState: 'car/pickServiceTypesState',
            }),
            gotoNextStep() {
                this.trackCar();
                this.switchStep(steps.workshopStep);
            },
            gotoPrevStep() {
                this.switchStep(steps.servicesStep);
            },
            regNoKeyup() {
                if (this.isNewRegNo() && this.checkRegNoValidy()) {
                    this.regnoPrev = this.regno;
                    var data = { RegNo: this.regno };
                    this.ajaxPickCar.post(data, this.pickCarSuccess());
                }
                this.milesValid = this.checkMilesValidy();
            },
            milesKeyUp() {

                this.milesValid = this.checkMilesValidy();
            },
            checkRegNoValidy() {
                this.regno = this.regno.replace(/[^0-9a-zA-Z]/g, '');
                return document.getElementById('offer-reg-number').checkValidity();
            },
            checkMilesValidy() {

                let value = document.getElementById('offer-miles').value;
                value = value.replace('.', '').replace(',', '');
                if (value.length > 6)
                    value = value.slice(0, 6);
                
                document.getElementById('offer-miles').value = value;
                return document.getElementById('offer-miles').checkValidity();
            },
            isNewRegNo() {
                return this.regnoPrev.toUpperCase() != this.regno.toUpperCase();
            },
            pickCarSuccess() {
            },
            serviceTypeSuccess() {
                if (this.services.length == 1) {
                    this.showServices = false;
                    this.selectedService = this.services[0].id;
                }
                else {
                    this.selectedService = '';
                    this.showServices = true;
                }
            },
            trackCar() {
                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewPickVehicleSuccess',
                        data: { selectedCar: this.selectedCar, selectedCarTotalPrice: null },
                    });
            }
        },
        async mounted() {
            this.ajaxPickCar = ajaxHelper(
                '/api/vehicle/setCar',
                this.pickCarState,
                2000,
                true,
                this.defaultTimeout
            );
            this.ajaxServiceTypes = ajaxHelper(
                '/api/applications/offers/getServiceTypes',
                this.serviceTypesState,
                2000,
                true,
                this.defaultTimeout);
        }
    }

</script>
