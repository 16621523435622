<template>
    <div v-if="stepVisible" class="offer__modal-step-wrapper">
        <ModalWrapper :stepValid="stepValid" :title="'Välj tjänst'" :hasBackBtn="false" @onNextStep="gotoNextStep">
            <div class="offer__modal-container">
                <ul>
                    <li v-for="(o, index) in serviceFiltered"
                        v-bind:key="index"
                        v-bind:style="'background-image: url(' + o.service.Image + ')'"
                        class="offer__select-row offer__select-row--has-image" 
                        v-bind:class="{ hidden: !o.enabled }">
                        <div class="offer__select-row__content">
                            <div class="offer__select-row__title">
                                {{o.service.Headline}}
                                <span class="offer__select-row__flag" v-if="isPriceAvailable(o.service) && !config.disablePrices">Pris direkt</span>
                                <span class="offer__select-row__flag discount" v-if="isDiscountAvailable(o.service) && !config.disablePrices">{{o.service.Items[0].DiscountOfferText}}</span>
                            </div>
                            <div>
                                <a href="#"
                                    class="offer__select-row__link"
                                   v-bind:id="'more-text-service' + index"
                                   v-on:click="showServiceText(index)">Läs mer</a>
                            </div>
                            <div style="display:none">
                                <div v-if="isSingleService(o.service)" v-html="o.service.Items[0].Description">
                                </div>
                                <div v-else v-html="o.service.Text">
                                </div>
                                <a href="#"
                                   class="offer__select-row__link"
                                   v-bind:id="'less-text-service' + index"
                                   v-on:click="hideServiceText(index)">Läs mindre</a>
                            </div>
                            <div class="mt-12" v-if="selectedMainServices.includes(index) && isSingleService(o.service) && o.service.Items[0].UseSpecificMessageField">
                                <input class="input-field-sm" type="text" placeholder="Beskriv vad du vill ha hjälp med..." v-model="commentsData[index].text" v-bind:id="'comment-service' + index" />
                            </div>
                        </div>
                        <div class="offer__select-row__action-container">
                            <div class="btn btn--icon"
                                 :class="{'btn--success': selectedMainServices.includes(index)}"
                                 @click="setService(index)">
                                {{ selectedMainServices.includes(index) ? '' : 'Välj' }}
                                <i v-if="selectedMainServices.includes(index)" class="fas fa-check btn__solo-icon"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </ModalWrapper>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex';
    import steps from '../constants/steps';
    import ModalWrapper from './ModalWrapper.vue';
    export default {
        components: { ModalWrapper },
        name: 'serviceStartStep',
        data() {
            return {
                preSelectedMainIndexs: [],
                preSelectedItems: [],
                commentsData: [],
                preselectedNextStep: null,

            }
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                services: state => state.config.services,
                mainServices: state => state.mainServices,
                isMainServicePrio: state => state.config.preselected.isMainServicePrio,
                preSelectedServices: state => state.config.preselected.serviceIds,
                preSelectedComment: state => state.config.preselected.comment,
                selectedServices: state => state.config.serviceIds,
                config: state => state.config,
                preSelectedWorkhopServices: state => state.preselectedWorkshop.services.data,
                commentItems: state => state.comments.commentMainItems
            }),
            preSelectedWorkhopId: {
                get() {
                    return this.config.workshopId;
                },
                set(value) {
                    this.$store.commit('config/updatePreselectedWorkshop', value);
                }
            },
            stepVisible: {
                get() {
                    return (this.stepProgress.currentStep == steps.serviceStartStep);
                },
            },
            selectedMainServices: {
                get() {
                    return this.mainServices.indexs;
                },
                set(value) {
                    const settingsParams = this.tryGetCarFormSetting(value)
                    this.updateServices({
                        indexs: value,
                        priceApiServices: settingsParams.useApis,
                        useMilageValidation: settingsParams.useMilageValidation
                    });
                    this.updateSelectedServices({ ids: [], items: [], preselectedLoad: false }); // reset if any
                }
            },
            stepValid: {
                get() {
                    return this.mainServices.indexs.length > 0;
                }
            },
            serviceFiltered: {
                get() {
                    let serviceObjects = this.services.map(item => {
                        return { service: item, enabled: true };
                    })

                    if (this.preSelectedWorkhopServices != null) {
                        this.filterByPreselectedWorkshop(serviceObjects);
                    }

                    return serviceObjects;
                },
            },
            comments: {
                get() {
                    return this.commentItems
                },
                set(value) {
                    this.$store.commit('comments/updateMainComments', value);
                }
            }
        },
        mounted() {

            this.services.forEach((s) => {
                let textValue = '';
                if (this.isPreselectedServices() && this.preSelectedComment.length) {
                    this.preselectedNextStep = this.preselectedNextStep == null ? steps.servicesStep : this.preselectedNextStep;
                    if (this.preSelectedServices == s.Items[0].Id.toString()) {
                        textValue = this.preSelectedComment;
                        this.preselectedNextStep = this.isSingleService(s) ? steps.serviceStartStep : steps.servicesStep;
                    }
                }
                this.commentsData.push({ id: s.Items[0].Id, text: textValue});
            });

            if (this.isMainServicePrio) {
                this.setService(0);
            }
        },
        watch: {
            selectedServices(value) {
                if (value.length) {
                    this.preSelectedMainIndexs = [];
                    this.preSelectedItems = [];
                    this.initPreselectedServices(value);
                    if (this.preSelectedItems.length) {
                        this.updateFromPreselected(this.preSelectedMainIndexs);
                        if (this.preselectedNextStep == null) {
                            this.switchStep(steps.carStep);
                        } else {
                            this.switchStep(this.preselectedNextStep);
                        }
                    }
                }
            },
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
                updateServices: 'mainServices/updateIndexs',
                updateSelectedServices: 'selectedServices/updateServices',
            }),
            setService(value) {
                let updatedIds = [];
                if (this.selectedMainServices.includes(value)) {
                    updatedIds = this.selectedMainServices.filter(id => id !== value);
                } else {
                    updatedIds = [...this.selectedMainServices, value];
                }
                this.selectedMainServices = updatedIds;
                this.tryCommentFocus(value);
            },
            tryCommentFocus(index) {
                setTimeout(() => {
                    let commentField = document.getElementById('comment-service' + index);
                    if (commentField)
                        commentField.focus();
                }, 200);
            },
            isPreselectedServices() {
                return this.preSelectedServices != undefined && this.preSelectedServices != '';
            },
            isPreselectedWorkshop() {
                return this.preSelectedWorkhopId.length;
            },
            initPreselectedServices(services) {
                this.services.forEach((m, i) => {

                    if (services.split(',').includes(m.Id.toString())) {
                        this.setService(i)
                    }

                    m.Items.forEach((s) => {
                        if (services.split(',').includes(s.Id.toString())) {
                            if (!this.preSelectedComment.length) {
                                this.preselectedNextStep = null;
                                if (s.UseSpecificMessageField && !this.preSelectedComment.length) {
                                    this.preselectedNextStep = this.isSingleService(m) ? steps.serviceStartStep : steps.servicesStep;
                                }
                            }
                            this.preSelectedMainIndexs.push(i);
                            this.preSelectedItems.push(s);
                        }
                    });
                });
            },
            updateFromPreselected(preSelectedMainIndexs) {
                let uniqueItems = [...new Set(preSelectedMainIndexs)]
                this.selectedMainServices = uniqueItems;
                this.updateSelectedServices({
                    ids: this.preSelectedItems.map((s) => (s.Id)),
                    items: this.preSelectedItems,
                    preselectedLoad: true
                });
            },
            gotoNextStep() {
                this.comments = this.commentsData;
                this.trackStartStep();
                this.switchStep(steps.servicesStep);
            },
            showServiceText(index) {
                this.toggleParents('less-text-service' + index, 'more-text-service' + index);
            },
            hideServiceText(index) {
                this.toggleParents('more-text-service' + index, 'less-text-service' + index);
            },
            toggleParents(elIdShow, elIdHide) {
                document.getElementById(elIdShow).parentElement.setAttribute('style', '');
                document.getElementById(elIdHide).parentElement.setAttribute('style', 'display:none');
            },
            tryGetCarFormSetting(ids) {
                let result = { useApis: [], useMilageValidation: false };
                ids.forEach((id) => {
                    const foundApi = this.services[id].Items.find(s => s.HasPriceAPI)
                    const foundMilage = this.services[id].Items.find(s => s.ShowMilage)
                    if (foundApi)
                        result.useApis.push(foundApi);
                    if (foundMilage)
                        result.useMilageValidation = true;
                });
                return result;
            },
            isSingleService(mainService) {
                return mainService.Items.length == 1;
            },
            showPriceAvailable(mainService) {
                if (this.isSingleService(mainService)) {
                    const service = mainService.Items[0];
                    return service.IsStaticPriceAllowed || service.EnabledPriceApi;
                }
                return false; 
            },
            filterByPreselectedWorkshop(serviceObjects) {
                serviceObjects.forEach((o) => {
                    o.enabled = false;
                    const subservicesIds = o.service.Items.map((i) => (i.Id));
                    this.preSelectedWorkhopServices.forEach((s) => {
                        if (subservicesIds.includes(s.serviceID))
                            o.enabled = true;
                    });    
                });
            },
            isPriceAvailable(service) {
                let isAvaiable = true;
                service.Items.forEach((s) => {
                    if (!s.IsStaticPriceAllowed && !s.EnabledPriceApi)
                        isAvaiable = false;
                });
                return isAvaiable;
            },
            isDiscountAvailable(service) {
                let isAvaiable = true;
                service.Items.forEach((s) => {
                    if (s.DiscountPercent == null)
                        isAvaiable = false;
                });
                return isAvaiable;
            },
            trackStartStep() {

                let names = [];
                this.mainServices.indexs.forEach(i => {
                    names.push(this.services[i].Headline);
                });
                this.$store.commit('config/trackAction',
                {
                    event: 'trackNewPickServiceCategory',
                    data: { selectedServiceCategory: names.join('/') },
                });
            }
        },
    }

</script>