export function formatPrice(amount, bare = false) {
  
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  amount = amount.toFixed();

  const suffix = bare ? '' : ' kr';
  
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + suffix;
}