import Vue from 'vue'
import Vuex from 'vuex'
import config from './modules/config';
import stepProgress from './modules/stepProgress';
import mainServices from './modules/mainServices';
import car from './modules/car';
import selectedServices from './modules/selectedServices';
import workshop from './modules/workshop';
import user from './modules/user';
import price from './modules/price';
import preselectedWorkshop from './modules/preselectedWorkshop';
import comments from './modules/comments';

Vue.use(Vuex)
const strict = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    strict,
    modules: {
        config,
        stepProgress,
        mainServices,
        car,
        selectedServices,
        workshop,
        user,
        price,
        preselectedWorkshop,
        comments
    }
});