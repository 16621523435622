<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content" :class="{'confirm-step': isConfirmStep}">
            <div class="modal-content-container">
                <div class="offer__modal-header modal-general-header modal-header-large">
                    <div v-if="enableHeaderOverlay" class="offer__modal-header__overlay"></div>
                    <a class="modal-close pull-right" href="#" data-dismiss="modal"><i class="far fa-times"></i></a>
                    <a class="modal-back-btn" href="#" v-if="hasBackBtn && !finalStep" v-on:click="$emit('onPrevStep')">
                        <i class="far fa-chevron-left"></i>
                    </a>
                    {{ title }}
                </div>
                <ul class="timeline" :class="{'timeline-done' : stepProgress.currentStep == 7}">
                    <li :class="{'active': stepProgress.currentStep >= 1}">
                        <div><span class="timeline-service" v-on:click="trySwitchStep(1)">Välj tjänst</span></div>
                        <div>
                            <span class="line"></span>
                            <span class="circle"></span>
                            <span class="line line-right" :class="{'active': stepProgress.currentStep >= 3}"></span>
                        </div>
                    </li>
                    <li :class="{'active': stepProgress.currentStep >= 3}">
                        <div><span class="timeline-service" v-on:click="trySwitchStep(3)">Ditt fordon</span></div>
                        <div>
                            <span class="line"></span>
                            <span class="circle"></span>
                            <span class="line line-right" :class="{'active': stepProgress.currentStep >= 4}"></span>
                        </div>
                    </li>
                    <li :class="{'active': stepProgress.currentStep >= 4}">
                        <div><span class="timeline-service" v-on:click="trySwitchStep(4)">Välj verkstad</span></div>
                        <div>
                            <span class="line"></span>
                            <span class="circle"></span>
                            <span class="line line-right" :class="{'active': stepProgress.currentStep >= 5}"></span>
                        </div>
                    </li>
                    <li :class="{'active': stepProgress.currentStep >= 5}">
                        <div><span class="timeline-service" v-on:click="trySwitchStep(5)">Dina uppgifter</span></div>
                        <div>
                            <span class="line"></span>
                            <span class="circle"></span>
                            <span class="line line-right" :class="{'active': stepProgress.currentStep >= 6}"></span>
                        </div>
                    </li>
                    <li :class="{'active': stepProgress.currentStep >= 6}">
                        <div><span class="timeline-service" v-on:click="trySwitchStep(6)">Granska</span></div>
                        <div>
                            <span class="line"></span>
                            <span class="circle"></span>
                            <span class="line line-right" :class="{'active': stepProgress.currentStep >= 7}"></span>
                        </div>
                    </li>
                </ul>
                <div class="modal-general-body" v-bind:style="{ 'overflow-y': hideScroll ? 'hidden' : 'auto' }">
                    <slot></slot>
                </div>
            </div>
            <div class="offer__modal-footer">
                <div class="offer__info" v-bind:class="{'price-request' : isPriceRequest}">
                    <div class="offer__contact-container" v-bind:class="{'hidden-sm' : (isPriceRequest || isConfirmStep)}">
                        <img src="../assets/AX-kortlogo-rgb.png" class="offer__contact-image" />
                        <div class="offer__info-container">
                            <div class="offer__info-meta">
                                {{texts.footerPhoneHeader}}
                            </div>
                            <div class="offer__info-text">
                                <a v-bind:href="'tel:' + texts.footerPhoneText">{{texts.footerPhoneText}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="offer__info-container offer__info-container--opening-hours" v-if="!isPriceRequest && !isConfirmStep">
                        <div class="offer__info-meta">
                            {{texts.footerOpenTimesHeader}}
                        </div>
                        <div class="offer__info-text">
                            {{texts.footerOpenTimesText}}
                        </div>
                    </div>
                </div>

                <div class="offer__modal-footer__actions">

                    <div v-if="priceRequestStatus != 1 && price && !isConfirmStep && !hidePrice" class="offer__modal-price-display">

                        <span class="price-teaser">Se pris i nästa steg!</span>

                    </div>
                    <div v-if="priceRequestStatus != 1 && price && isConfirmStep && !hidePrice" class="offer__modal-price-display">
                    </div>

                    <div class="modal-btn" v-if="isUserStep" v-on:click="$emit('onNextStep')">
                        {{ nextStepText }}
                        <i class="fas fa-arrow-right"></i>
                    </div>

                    <a v-else-if="finalStep" class="modal-btn " href="#" data-dismiss="modal"
                       :class="{'hidden': preSelectedWorkhopId.length > 0}">Stäng</a>


                    <div class="modal-btn"
                         v-else
                         v-on:click="stepValid && $emit('onNextStep')"
                         :class="{'modal-btn--disabled': !stepValid }">
                        {{ nextStepText }}
                        <i v-if="!isConfirmStep" class="fas fa-arrow-right"></i>
                    </div>

                </div>

            </div>
            <div v-if="isConfirmStep" class="offer__modal-agreements">
                Genom att boka godkänner jag <a v-bind:href="texts.agreementLink" target="_blank">villkoren</a> hos Autoexperten.
            </div>
        </div>
    </div>

</template>

<script>
    import { formatPrice } from '../utils/formatPrice';
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'modalWrapper',
        props: {
            stepValid: Boolean,
            isConfirmStep: Boolean,
            hasBackBtn: Boolean,
            finalStep: Boolean,
            enableHeaderOverlay: Boolean,
            nextLabel: String,
            title: String,
            price: Number || Boolean,
            priceRequestStatus: Number || Boolean,
            isPriceRequest: Boolean,
            hideScroll: Boolean,
            isUserStep: Boolean,
        },
        data() {
            return {
                formatPrice,
                isTermsPolicy: false,
                isNewsletter: false,
                isTermsPolicyTerms: false,
            }
        },
        computed: {
            ...mapState({
                texts: state => state.config.texts,
                hidePrice: state => state.config.disablePrices,
                allwaysAllowBooking: state => state.config.allwaysAllowBooking,
                autoFillAndHideConditions: state => state.config.disableConditions,
                preSelectedWorkhopId: state => state.config.workshopId,
                stepProgress: state => state.stepProgress,
            }),
            nextStepText: {
                get() {
                    if (this.isConfirmStep && (this.price || this.allwaysAllowBooking))
                        return this.texts.userForm.submitBooking;
                    else if (this.isConfirmStep)
                        return this.texts.userForm.submitOffertLong;
                    else
                        return this.nextLabel ? this.nextLabel : 'Fortsätt';
                },
            },
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
            }),
            trySwitchStep(step) {
                if (this.stepProgress.currentStep > step && this.stepProgress.currentStep < 7) {
                    this.switchStep(step);
                }
            },
        }
    }

</script>

<style lang="scss" scoped>
    .spinner-price-loading {
        width: 20px;
        margin: 0 10px;
    }
    @media (max-width: 767px) {
        .price-request {
            display: none;
        }
    }
    .termslink-toggle {
        display: contents;
        color: #0845F4;
        font-weight: 500;
        text-decoration: underline;
    }
</style>