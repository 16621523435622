import RequestStates from "../../constants/RequestStates";
import debounce from './../utils/debounce.js';
import axios from 'axios';

const defaultState = () => ({
    status: null,
    data: null,
    error: null,
});

var requestObject = function (_url, upateAjaxState, dTime, runAtOnce, _timeout) {

    var state = defaultState(); 
    if (upateAjaxState) {
        upateAjaxState(state);
    }
   
    var debounceTime = 250;
    if (dTime != undefined) {
        debounceTime = dTime;
    }
    var CancelToken = axios.CancelToken;
    var cancel= null;
    var url = _url;
    var timeout = 30000;
    if (_timeout != undefined) {
        timeout = _timeout;
    }
    return {
        // eslint-disable-next-line no-unused-vars
        post: debounce((data, success, error) => {
            state = defaultState();
            state.status = RequestStates.Request;
            if (upateAjaxState) {
                upateAjaxState(state);
            }
            if (cancel) {
                cancel('end');
            }
            // save the new request for cancellation
            axios.post(url, data,{
                  // eslint-disable-next-line no-unused-labels
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                }),
                timeout: timeout,
            }).then(result => {
                if (result.status==200) {
                    state.status = RequestStates.Success;
                    state.data = result.data;
                    if (upateAjaxState) { upateAjaxState(state); }
                    if (success) { success(result.data) }
                } else {
                    state.status = RequestStates.Failure;
                    state.error = result.data;
                    if (upateAjaxState) { upateAjaxState(state); }
                    if (error) { error(result) }
                }
            }).catch(e => {
                state.status = RequestStates.Failure;
                state.error = e;
                if (upateAjaxState) { upateAjaxState(state);  }
                if (error) { error(e) }
            }, this);   
        }, debounceTime, runAtOnce ),
        get: debounce((data, success, error) => {
            state = defaultState();
            state.status = RequestStates.Request;
            if (upateAjaxState) {
                upateAjaxState(state);
            }
            if (cancel) {
                cancel('end');
            }
            // save the new request for cancellation
            axios.get(url, data,{
                  // eslint-disable-next-line no-unused-labels
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                }),
                timeout: timeout,
            }).then(result => {
                if (result.status==200) {
                    state.status = RequestStates.Success;
                    state.data = result.data;
                    if (upateAjaxState) { upateAjaxState(state); }
                    if (success) { success(result.data) }
                } else {
                    state.status = RequestStates.Failure;
                    state.error = result.data;
                    if (upateAjaxState) { upateAjaxState(state); }
                    if (error) { error(result) }
                }
            }).catch(e => {
                state.status = RequestStates.Failure;
                state.error = e;
                if (upateAjaxState) { upateAjaxState(state);  }
                if (error) { error(e) }
            }, this);   
        }, debounceTime, runAtOnce ),
    };
};


export default requestObject;