/*eslint-disable */

export default function debounce(func, wait,runAtOnce) {
    var timeout;
    var runFunction;
    var _runAtOnce = true;
    if (runAtOnce != undefined) {
        _runAtOnce = runAtOnce;
    }
    var args;
    var context;

    return function () {
      
        context = this;
        args = arguments;

      
        if (_runAtOnce == true) {
            if (timeout) {
                runFunction = func;

            } else {

                var block = function () {
                    timeout = null;
                    if (runFunction) {
                        runFunction.apply(context, args);
                     
                    }
                };
                runFunction = null;
                timeout = setTimeout(block, wait);
                func.apply(context, args);
    
            }
        } else {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            var block = function () {
                func.apply(context, args);
                
            };
            timeout = setTimeout(block, wait);

        }
    };
};
 