const defaultValue = () => ({
    input: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        newsletterChecked: false,
        termsPolicyTerms: false,
    }
});

const state = defaultValue();
const getters = {};
const actions = {};
const mutations = {
    firstname(state, value) {
        state.input = { ...state.input, firstname: value };
    },
    lastname(state, value) {
        state.input = { ...state.input, lastname: value };
    },
    phone(state, value) {
        state.input = { ...state.input, phone: value };
    },
    company(state, value) {
        state.input = { ...state.input, company: value };
    },
    email(state, value) {
        state.input = { ...state.input, email: value };
    },
    message(state, value) {
        state.input = { ...state.input, message: value };
    },
    newsletter(state, value) {
        state.input = { ...state.input, newsletterChecked: value };
    },
    termsPolicy(state, value) {
        state.input = { ...state.input, termsPolicyTerms: value };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};