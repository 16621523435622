<template>
    <div id="app-offer-v3" class="offer" :class="{ 'custom-style': hasCustomStyle }">
        <div class="app-offer-breadcrumbs">
            <a v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.href" :href="breadcrumb.href">
                <span v-if="index != 0">></span>
                {{ breadcrumb.text }}
            </a>
        </div>
        <div class="offer__banner-display" :class="{ hidden: config.hideAppOpener, 'offer__banner-topmargin': config.hasTopMargin }" :style="appStyle">
            <h3>
                {{config.heading}}
            </h3>
            <div v-if="config.texts.preambleText.length" class="preamble">
                {{config.texts.preambleText}}
            </div>
            <div v-if="preSelectedServiceName == null" class="offer__banner-display__action-container" data-toggle="modal" data-target="#app-offer-v3-modal" href="#" data-source="app-offer-v3-modal" v-on:click="modalClick" style="cursor:pointer">
                <div class="offer__banner-display__title-container">
                    <i class="fas fa-tools offer__banner-display__title-icon"></i>
                    Välj tjänst
                </div>
                <a class="btn btn-ext">
                    Välj
                </a>
            </div>
            <div v-else>

                <div class="offer__banner-display__action-container">
                    <div class="offer__banner-display__title-container">
                        <i class="fas fa-tools offer__banner-display__title-icon"></i>
                        {{preSelectedServiceName}}
                    </div>
                    <div class="btn btn--icon btn--success btn-ext"><i data-v-0c88c656="" class="fas fa-check btn__solo-icon"></i></div>
                </div>

                <div class="offer__banner-display__action-container offer__banner-display__action-container-ws" data-toggle="modal" data-target="#app-offer-v3-modal" href="#" data-source="app-offer-v3-modal" v-on:click="modalClick" style="cursor:pointer">
                    <div class="offer__banner-display__title-container">
                        <i class="fas fa-map-marker-alt offer__banner-display__title-icon"></i>
                        Välj verkstad
                    </div>
                    <a class="btn btn-ext">
                        Välj
                    </a>
                </div>

            </div>

            <div v-html="config.texts.contentText" class="offer__text">
            </div>
            <div v-for="item in this.config.texts.bulletList" v-bind:key="item" class="bulletlist">
                <div class="success-check-mark">
                    <i class="fa fa-check"></i>
                </div>
                {{item}}
            </div>
            <div class="offer__info" v-if="config.hideContactInfo == false">
                <div class="offer__contact-container">
                    <img src="/Static/img/png/AX-kortlogo-rgb.png" class="offer__contact-image" />
                    <div class="offer__info-container">
                        <div class="offer__info-meta">
                            {{config.texts.footerPhoneHeader}}
                        </div>
                        <div class="offer__info-text">
                            <a v-bind:href="'tel:' + config.texts.footerPhoneText">{{config.texts.footerPhoneText}}</a>
                        </div>
                    </div>
                </div>
                <div class="offer__info-container hidden-sidebar">
                    <div class="offer__info-meta">
                        {{config.texts.footerOpenTimesHeader}}
                    </div>
                    <div class="offer__info-text">
                        {{config.texts.footerOpenTimesText}}
                    </div>
                </div>
            </div>
        </div>

        <div class="offer__fixed-display" :class="{ hidden: config.hideAppOpener }">
            <div data-toggle="modal" data-target="#app-offer-v3-modal" href="#" data-source="app-offer-v3-modal" v-on:click="modalClick" class="offer__fixed-display__container">
                <div class="offer__fixed-display__container__info">
                    {{config.texts.offerMobileHeader}}
                </div>
                <div class="offer__fixed-display__container__btn">
                    <a class="btn btn-ext">
                        Boka nu
                    </a>
                </div>
            </div>
        </div>

        <div id="app-offer-v3-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="login-modal" aria-hidden="true">

            <ServiceStartStep />
            <CarStep />
            <ServicesStep />
            <WorkshopStep />
            <UserFormStep />
            <ConfirmStep />
            <ThanksStep />

        </div>

    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import steps from './constants/steps';
    import ServiceStartStep from './components/ServiceStartStep.vue';
    import CarStep from './components/CarStep.vue';
    import ServicesStep from './components/ServicesStep.vue';
    import WorkshopStep from './components/WorkshopStep.vue';
    import UserFormStep from './components/UserFormStep.vue';
    import ConfirmStep from './components/ConfirmStep.vue';
    import ThanksStep from './components/ThanksStep.vue';
    import ajaxHelper from './store/utils/ajaxHelper';

    export default {
        name: 'app-offer-v3',
        components: {
            ServiceStartStep,
            CarStep,
            ServicesStep,
            WorkshopStep,
            UserFormStep,
            ConfirmStep,
            ThanksStep
        },
        data() {
            return {
                preSelectedServiceName: null,
                appStyle: '',
                hasCustomStyle: false,
                breadcrumbs: [],
            }
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                config: state => state.config,
                preSelectedServices: state => state.config.preselected.serviceIds,
                services: state => state.config.services,
                preSelectedWorkhopServices: state => state.preselectedWorkshop.services.data,
                selectedServices: state => state.config.serviceIds,
                mainServicesIds: state => state.mainServices.indexs,
            }),
            isMobile() {
                return this.windowWidth < 768;
            },
            preSelectedWorkhopId: {
                get() {
                    return this.config.workshopId;
                },
                set(value) {
                    this.$store.commit('config/updatePreselectedWorkshop', value);
                }
            },
            preSelectedServiceIds: {
                get() {
                    return this.config.serviceIds;
                },
                set(value) {
                    this.$store.commit('config/updatePreselectedServices', value);
                }
            },
        },
        mounted() {

            this.ajaxGetServices = ajaxHelper(
                '/api/applications/offers/v2/getServicesFromWorkshop',
                this.pickServices,
                2000,
                true,
                this.defaultTimeout
            );

            if(this.stepProgress.currentStep < 1)
                this.$store.commit('stepProgress/switchToStep', steps.serviceStartStep);

            this.setSessionUniqueId();
            this.updatePreSelectedServiceName();
            this.applyAppStyle();

            if (document.body.className == 'offer-iframed') {
                this.reloadWorkshopServices();
            }

        },
        methods: {
            ...mapActions({
                setSessionUniqueId: 'config/setSessionUniqueId',
                updateWorkshopId: 'config/updateWorkshopId',
                pickServices: 'preselectedWorkshop/pickServices',
                reloadConfig: 'config/load',
            }),
            modalClick() {

                const isWorkhopChange = (this.config.preselected.workshopId != window.workshopId && window.workshopId.length);
                const isServiceChange = (this.preSelectedServiceIds != window.serviceIds && window.serviceIds.length);

                if (isWorkhopChange) {
                    if (window.serviceIds.length)
                        this.resetSession(false);
                    else
                        this.resetSession(true);
                }
                else if (isServiceChange) {
                    this.resetSession(true);
                }
                else if (this.stepProgress.currentStep == steps.thanksStep)
                    this.resetSession(true);

                if (window.serviceIds.length) {
                    this.reloadServices();
                }

                if (this.preSelectedWorkhopId != window.workshopId) {
                    this.reloadWorkshopServices();
                }

                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewOpenPickServices',
                        data: { initiatedPickServices: 1 }
                    });
            },
            resetSession(includeIndexs) {
                this.setSessionUniqueId();
                if (includeIndexs)
                    this.$store.commit('mainServices/updateIndexs', { indexs: [], priceApiServices: [], useMilageValidation: false });
                this.$store.commit('comments/updateMainComments', []);
                this.$store.commit('comments/updateComments', []);
                this.$store.commit('stepProgress/switchToStep', steps.serviceStartStep);
                this.$store.commit('user/message', '');
            },
            isPreselectedServices() {
                return this.preSelectedServices != undefined && this.preSelectedServices != '';
            },
            updatePreSelectedServiceName() {
                if (this.isPreselectedServices()) {
                    this.services.forEach((m) => {
                        m.Items.forEach((s) => {
                            if (this.preSelectedServices.split(',').includes(s.Id.toString())) {
                                this.preSelectedServiceName = (s.NameAlt == null) ? s.Name : s.NameAlt;
                            }
                        });
                    });
                }
            },
            applyAppStyle() {
                if (this.config.bgColor.length)
                    this.appStyle = 'background-color:' + this.config.bgColor + ';';
                if (this.config.bgImage.length)
                    this.appStyle += 'background-image: url(' + this.config.bgImage + ');';
                if (this.appStyle.length)
                    this.hasCustomStyle = true;
            },
            reloadWorkshopServices()
            {
                this.$store.commit('stepProgress/switchToStep', steps.none);
                this.preSelectedWorkhopId = window.workshopId;
                this.ajaxGetServices.post({ selectedWorkshop: window.workshopId }, this.workshopServiceDone);
            },
            reloadServices() {
                this.preSelectedServiceIds = window.serviceIds;
            },
            workshopServiceDone() {
                let switchStep = steps.serviceStartStep;
                if (window.serviceIds.length) {
                    if (this.preSelectedWorkhopServices.map((i) => (i.serviceID.toString())).includes(this.selectedServices)) {
                        if (this.mainServicesIds.length)
                            switchStep = steps.servicesStep;
                    }
                    else
                        this.resetSession(true);
                }
                this.$store.commit('stepProgress/switchToStep', switchStep);
            },
            tryApplyBreadcrumbs(crumbSource) {
                for (let i = 0; i < crumbSource.length; i++) {
                    this.breadcrumbs.push({
                        text: crumbSource[i].text,
                        href: crumbSource[i].href
                    });
                }
            }
        }
  }

</script>

<style lang="scss" scoped>
    .modal-dialog {
        width: 720px;
        max-width: 95%;
        margin: 150px auto;
    }
    .modal-general-body {
        padding: 24px;
    }
    .app-offer-breadcrumbs {
        position: absolute;
        margin-top: 12px;
        margin-left: 32px;

        @media (max-width: 767px) {
            margin-top: 6px;
            margin-left: 4px;
            font-size: 12px;
        }
    }
</style>