const defaultValue = () => ({
    items: [],
    ids: [],
    preselectedLoad: false
});

const state = defaultValue();

const getters = {
    pricePossible: state => {
        let pricePossible = true;
        state.items.forEach((x) => {
            if (x.HasPriceAPI == false && x.IsStaticPriceAllowed == false)
                pricePossible = false;
        })
        return pricePossible;
    },
    includesPriceApi: state => {
        let apiPricePossible = false;
        state.items.forEach((x) => {
            if (x.HasPriceAPI)
                apiPricePossible = true;
        })
        return apiPricePossible;
    },
    includesStaticPrice: state => {
        let includesStaticPrice = false;
        state.items.forEach((x) => {
            if (x.IsStaticPriceAllowed)
                includesStaticPrice = true;
        })
        return includesStaticPrice;
    },
};

const actions = {
    updateServices({ commit }, value) {
        commit('updateServices', value);
    },
};

const mutations = {
    updateServices(state, value) {
        state.ids = value.ids;
        state.items = value.items;
        state.preselectedLoad = value.preselectedLoad;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};