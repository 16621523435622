import Vue from 'vue'
import App from './App.vue'
import store from './store';
import moment from 'moment';
import 'moment/locale/sv'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production';

moment().locale('sv');

var offertApp = new Vue({
    render: h => h(App),
    store,
    created() {
        store.dispatch('config/load');
    },
}).$mount('#app-offer-v3')

window.offertApp = offertApp;