const defaultValue = () => ({
    indexs: [],
    priceApiServices: [],
    useMilageValidation: false
});

const state = defaultValue();
const getters = {};

const actions = {
    updateIndexs({ commit }, value) {
        commit('updateIndexs', value);
    },
};

const mutations = {
    updateIndexs(state, value) {
        state.indexs = value.indexs;
        state.priceApiServices = value.priceApiServices;
        state.useMilageValidation = value.useMilageValidation;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};