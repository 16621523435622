const defaultValue = () => ({
     services: {
        status: null,
        data: null,
        error: null,
    }
});

const state = defaultValue();
const getters = {};
const actions = {
    pickServices({ commit }, value) {
        commit("pickServices", value);
    },
}
const mutations = {
    pickServices(state, value) {
        if (value.data != null && !value.data.length) {
            value.data = null;
        }
        state.services = Object.assign({}, value);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};