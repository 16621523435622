const defaultValue = () => ({
    pickCarState: {
        status: null,
        data: null,
        error: null,
    },
    serviceTypesState: {
        status: null,
        data: null,
        error: null,
    },
    selectedService: '',
    miles: null
});
const state = defaultValue();
const getters = {};

const actions = {
    pickCarState({ commit }, value) {
        commit("pickCarState", value);
    },
    pickServiceTypesState({ commit }, value) {
        commit("pickServiceTypesState", value);
    },
    updateMilesState({ commit }, value) {
        commit("updateMilesState", value);
    },
    updateSelectedService({ commit }, value) {
        commit("updateSelectedService", value);
    },
}
const mutations = {
    pickCarState(state, value) {
        state.pickCarState = Object.assign({}, value);
    },
    pickServiceTypesState(state, value) {
        state.serviceTypesState = Object.assign({}, value);
    },
    updateMilesState(state, value) {
        state.miles = value;
    },
    updateSelectedService(state, value) {
        state.selectedService = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};