const defaultValue = () => ({
    commentItems: [],
    commentMainItems: [],
});

const state = defaultValue();
const getters = {};

const actions = {
    updateComments({ commit }, value) {
        commit('updateComments', value);
    },
    updateMainComments({ commit }, value) {
        commit('updateMainComments', value);
    },
};

const mutations = {
    updateComments(state, value) {
        state.commentItems = value;
    },
    updateMainComments(state, value) {
        state.commentMainItems = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};