<template>

    <div v-if="stepVisible" class="offer__modal-step-wrapper">
        <ModalWrapper :stepValid="stepValid"
                      :hasBackBtn="true"
                      :title="'Dina uppgifter'"
                      :price="(price.isPriceRequest && price.priceLoaded && !price.priceError) && price.totalPrice || 0"
                      :enableHeaderOverlay="isDetailsOpen"
                      :priceRequestStatus="priceStatus"
                      :isPriceRequest="isPriceRequest"
                      :nextButtonActive="true"
                      :isUserStep="true"
                      @onNextStep="gotoNextStep"
                      @onPrevStep="gotoPrevStep">

            <PriceDetailsUser  @openDetails="onOpenDetails"  />
            
            <div class="offer__modal-container offer__user-form">
                <div id="offer-user-form">

                    <div class="offer__input-container-wrapper">

                        <div class="offer__input-container-60">
                            <i class="fal fa-user offer__input-icon"></i>

                            <input type="text" v-model="firstname" placeholder="Förnamn"
                                   minlength="2"
                                   maxlength="60"
                                   required id="offer-firstname" v-on:focusout="validateThis($event)" />
                            <i class="far fa-check"></i>

                        </div>

                        <div class="offer__input-container-40">
                            <i class="fal fa-user offer__input-icon"></i>
                            <input type="text" v-model="lastname" placeholder="Efternamn"
                                   minlength="2"
                                   maxlength="100"
                                   required v-on:focusout="validateThis($event)" />
                            <i class="far fa-check"></i>
                        </div>

                        <div class="offer__input-container-60">
                            <i class="fal fa-at offer__input-icon"></i>
                            <input type="email" v-model="email" placeholder="E-post"
                                   required v-on:focusout="validateThis($event)" />
                            <i class="far fa-check"></i>
                        </div>

                        <div class="offer__input-container-40">
                            <i class="fal fa-mobile offer__input-icon"></i>
                            <input type="text" v-model="phone" placeholder="Telefon"
                                   minlength="7"
                                   maxlength="20"
                                   required v-on:focusout="validateThis($event)" />
                            <i class="far fa-check"></i>
                        </div>
                    </div>

                    <div class="offer__input-container-wrapper" v-if="showCompanyInputBox">
                        <div class="offer__input-container-60">
                            <i class="fal fa-building offer__input-icon"></i>
                            <input v-if="isCompanyInputBoxRequred" type="text" v-model="company" placeholder="Företagsnamn"
                                   minlength="2"
                                   maxlength="100"
                                   required v-on:focusout="validateThis($event)"/>
                            <input v-else type="text" v-model="company" placeholder="Företagsnamn"
                                   minlength="2"
                                   maxlength="100" />
                            <i v-if="isCompanyInputBoxRequred" class="far fa-check"></i>
                        </div>
                    </div>

                    <div class="offer__input-container-100">
                        <i class="fal fa-envelope offer__input-icon"></i>
                        <textarea v-model="message" placeholder="Meddelande" maxlength="1500" />
                    </div>

                    <div v-if="showPolicyHint" class="terms-policy-hint">
                        <p>Du måste godkänna vår personuppgiftspolicy för att gå vidare med bokningen</p>
                        <div class="arrow-down"></div>
                    </div>

                    <div class="offer__input-container-100 offer__info__checkbox-container" v-bind:style="{ 'display': autoFillAndHideConditions ? 'none' : '' }">
                        <template v-if="autoFillAndHideConditions">
                            <input id="termsPolicyOk" name="terms" type="checkbox" v-model="termsPolicy" />
                        </template>
                        <template v-else>
                            <input id="termsPolicyOk" required name="terms" type="checkbox" v-model="termsPolicy" v-on:click="validateThis($event)" />
                        </template>

                        <label for="termsPolicyOk">
                            Jag samtycker till Autoexpertens
                            <a href="#" class="termslink-toggle" v-on:click="toggleShowTerms">personuppgiftspolicy</a>
                        </label>

                    </div>

                    <div class="offer__input-container-100 offer__info__checkbox-container" v-bind:style="{ 'display': autoFillAndHideConditions ? 'none' : '' }">
                        <input id="newsletterOk" type="checkbox" v-model="newsletter" />
                        <label for="newsletterOk">Jag samtycker till Autoexpertens marknadsföring</label>
                    </div>

                </div>

                <div v-if="showTerms">
                    <h4>{{texts.termsHeading }}</h4>
                    <div v-html="texts.termsDescription"></div>
                </div>

            </div>
        </ModalWrapper>

    </div>

</template>

<script>

    import { mapState, mapActions } from 'vuex';
    import steps from '../constants/steps';
    import PriceDetailsUser from './PriceDetailsUser.vue';
    import ModalWrapper from './ModalWrapper.vue';
    import axios from 'axios';

    export default {
        name: 'userFormStep',
        components: {
            PriceDetailsUser,
            ModalWrapper,
        },
        data() {
            return {
                stepValid: false,
                isDetailsOpen: false,
                trackedPrice: 0,
                showTerms: false,
                showPolicyHint: false,
            };
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                input: state => state.user.input,
                price: state => state.price,
                selectedServicesIds: state => state.selectedServices.ids,
                texts: state => state.config.texts,
                car: state => state.car,
                carstate: state => state.car.pickCarState,
                workshopId: state => state.workshop.selectedWorkshopId,
                hidePrice: state => state.config.disablePrices,
                showCompanyInputBox: state => state.config.showCompanyInputBox,
                isCompanyInputBoxRequred: state => state.config.isCompanyInputBoxRequred,
                autoFillAndHideConditions: state => state.config.disableConditions,
            }),
            stepVisible: {
                get() {
                    return (this.stepProgress.currentStep == steps.userFormStep);
                },
            },
            priceStatus: {
                get() {
                    return this.price.servicePriceState.status
                }
            },
            isPriceRequest: {
                get() {
                    return this.price.isPriceRequest
                }
            },
            firstname: {
                get() {
                    return this.input.firstname;
                },
                set(value) {
                    this.$store.commit('user/firstname', value);
                }
            },
            lastname: {
                get() {
                    return this.input.lastname;
                },
                set(value) {
                    this.$store.commit('user/lastname', value);
                }
            },
            email: {
                get() {
                    return this.input.email;
                },
                set(value) {
                    this.$store.commit('user/email', value);
                }
            },
            phone: {
                get() {
                    return this.input.phone;
                },
                set(value) {
                    this.$store.commit('user/phone', value);
                }
            },
            company: {
                get() {
                    return this.input.company;
                },
                set(value) {
                    this.$store.commit('user/company', value);
                }
            },
            message: {
                get() {
                    return this.input.message;
                },
                set(value) {
                    this.$store.commit('user/message', value);
                }
            },
            newsletter: {
                get() {
                    return this.input.newsletterChecked;
                },
                set(value) {
                    this.$store.commit('user/newsletter', value);
                }
            },
            termsPolicy: {
                get() {
                    return this.input.termsPolicyTerms;
                },
                set(value) {
                    this.$store.commit('user/termsPolicy', value);
                }
            },
            servicePriceStatus: {
                get() {
                    return this.price.servicePriceState.status;
                }
            },
            workshopServicesStatus: {
                get() {
                    return this.price.workshopServices.status;
                }
            },
            priceError: {
                get() {
                    return this.price.priceError;
                }
            },
        },
        mounted() {
            if (this.autoFillAndHideConditions)
                this.termsPolicy = true;
        },
        watch: {
            servicePriceStatus() {
                this.$store.commit('price/checkPriceStates', this.selectedServicesIds);
                this.triggerUpdatePrePayment();
            },
            workshopServicesStatus() {
                this.$store.commit('price/checkPriceStates', this.selectedServicesIds);
                this.triggerUpdatePrePayment();
            },
            stepVisible(visible) {
                if (visible)
                    setTimeout(function () { document.getElementById('offer-firstname').focus() }.bind(this), 100);
            },
            priceError(value) {
                if (value)
                    this.trackPriceError();
            }
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
            }),
            gotoNextStep() {

                this.validateAllFields();
                if (this.stepValid) {
                    this.trackPreOffer();
                    this.trackSuccess();
                    this.tryTrackPrice();
                    this.switchStep(steps.confirmStep);
                }
            },
            gotoPrevStep() {
                this.switchStep(steps.workshopStep);
            },
            onOpenDetails(isDetailsOpen) {
                this.isDetailsOpen = isDetailsOpen;
            },
            validateThis(event) {
                const el = event.target;
                if (el.checkValidity()) {
                    el.classList.remove('invalid-field');
                } else {
                    el.classList.add('invalid-field');
                }
            },
            validateAllFields() {
                let valid = true;
                this.showPolicyHint = false;
                document.querySelectorAll('#offer-user-form input').forEach((el) => {
                    el.classList.remove('invalid-field');
                    if (!el.checkValidity()) {
                        el.classList.add('invalid-field');
                        valid = false;
                        if (el.id === 'termsPolicyOk')
                            this.showPolicyHint = true;
                    }
                });
                this.stepValid = valid;
            },
            trackSuccess() {
                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewCustomerInfoSuccess',
                        data: { selectedCar: this.selectedCar, customerInfoSuccess: 1 },
                    });
            },
            toggleShowTerms() {
                this.showTerms = !this.showTerms;
            },
            tryTrackPrice() {
                if (this.priceLoaded) {
                    let price = Math.round(this.price.totalPrice);
                    if (price != this.trackedPrice && price > 0)
                        this.$store.commit('config/trackAction',
                            {
                                event: 'trackNewViewedFixedPrice',
                                data: { price: price },
                            });
                        this.trackedPrice = price;
                }
            },
            triggerUpdatePrePayment() {
                let url = '/api/applications/offers/getprepaymentinfo?price=' + encodeURIComponent(this.price.totalPrice);
                axios.get(url).
                    then(r => {
                        this.$store.commit('price/updatePartPayment', r.data);
                    });
            },
            trackPriceError() {
                this.$store.commit('config/trackAction',
                    {
                        event: 'trackNewFailedFixedPrice',
                        data: {
                            regNo: this.car.pickCarState.data.regNo,
                            selectedService: this.car.selectedService,
                            miles: this.car.miles,
                            workshopId: this.workshopId
                        },
                    });
            },
            trackPreOffer() {
                var bodyFormData = new FormData();
                bodyFormData.append('id', this.email);
                bodyFormData.append('type', 'preoffer');
                bodyFormData.append('regno', this.carstate.data.regNo);
                bodyFormData.append('firstname', this.firstname);
                bodyFormData.append('lastname', this.lastname);
                bodyFormData.append('phone', this.phone);
                bodyFormData.append('newsletter', this.newsletter);
                axios({
                    method: 'post',
                    url: '/DoLeadAction/',
                    data: bodyFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .catch(function (response) {
                        // eslint-disable-next-line no-console
                        console.log(response);
                    });
            },
        },
    }

</script>
