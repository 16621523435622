const defaultValue = () => ({
    currentStep: 0
});

const state = defaultValue();
const getters = {};


const actions = {
    switchToStep({ commit }, value) {
        commit('switchToStep', value);
    }
};

const mutations = {
    switchToStep(state, value) {
        state.currentStep = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};