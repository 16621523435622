<template>

    <!-- disable price view for now (false) -->
    <div v-if="false && price.isPriceRequest && price.priceLoaded && !price.priceError && !hidePrice" class="offer__price-display">

        <div v-if="!open" class="offer__pop-up__open-tab">
            <a href="#" v-on:click="openDetails">{{texts.carform.showParts}}</a>
            <i class="fal fa-chevron-up"></i>
        </div>

        <div class="offer__pop-up__overlay" v-if="open"></div>
        <div class="offer__pop-up" v-if="open">

            <div class="offer__pop-up__close-tab" @click="closeDetails">
                Stäng
                <i class="fal fa-chevron-down"></i>
            </div>

            <div class="offer__price-display__content">
                <div v-if="price.servicePriceState.data != null && (price.servicePriceState.data.priceInfo != null || price.servicePriceState.data.simplePriceInfos != null)">

                    <div class="offer__price-display__section-header">Vad ingår?</div>
                    <ul class="offer__price-display__section offer__price-display__section--list">
                        <template v-if="price.servicePriceState.data != null && price.servicePriceState.data.priceInfo != null">
                            <li v-for="(article, index) in price.servicePriceState.data.priceInfo.articles"
                                v-bind:key="'articles-' + index">
                                {{ article.quantity }} st {{ article.name }}
                            </li>
                        </template>
                        <template v-if="price.servicePriceState.data != null && price.servicePriceState.data.simplePriceInfos != null">
                            <template v-for="info in price.servicePriceState.data.simplePriceInfos">
                                <li v-for="(article, index) in info.articles"
                                    v-bind:key="'articles-' + index">
                                    <template v-if="article.isMissing">
                                        1 st {{ article.partDescription }}
                                    </template>
                                    <template v-else>
                                        1 st {{ article.name }}
                                    </template>
                                </li>
                            </template>
                        </template>
                    </ul>

                </div>

                <div class="offer__price-display__section-header">Kostnader</div>
                <div class="offer__price-display__section offer__price-display__section--cost pd-summary" :class="{'pd-summary-showheadandbreaks' : showHeaderAndBreaks}">
                    <div v-if="price.servicePriceState.data != null && price.servicePriceState.data.priceInfo != null" class="pd-item pd-head">
                        <span class="pd-text">Service</span>
                    </div>
                    <div v-if="price.servicePriceState.data != null && price.servicePriceState.data.priceInfo != null" class="pd-item">
                        <span class="pd-text">{{ texts.timeslot.articlecost }}:</span>
                        <span class="pd-price">{{ price.servicePriceState.data.priceInfo.articleCostStringVat }} kr</span>
                    </div>
                    <div v-if="price.servicePriceState.data != null && price.servicePriceState.data.priceInfo != null" class="pd-item pd-break">
                        <span class="pd-text">{{ texts.timeslot.workcost }}:</span>
                        <span class="pd-price">{{ price.servicePriceState.data.priceInfo.workAndDisposablesCostStringVat }} kr</span>
                    </div>

                    <template v-if="price.servicePriceState.data !=null">
                        <div v-for="(service, index) in price.servicePriceState.data.simplePriceInfos"
                             v-bind:key="'simpleprices-' + index" style="display:contents">
                            <div class="pd-item pd-head">
                                <span class="pd-text">{{service.serviceName}}</span>
                            </div>
                            <div class="pd-item">
                                <span class="pd-text">{{ texts.timeslot.articlecost }}:</span>
                                <span class="pd-price">{{ service.articleCostStringVat }} kr</span>
                            </div>
                            <div class="pd-item pd-break">
                                <span class="pd-text">{{ texts.timeslot.workcost }}:</span>
                                <span class="pd-price">{{ service.workAndDisposablesCostStringVat }} kr</span>
                            </div>
                        </div>
                    </template>

                    <div v-for="(service, index) in price.servicePrices"
                         v-bind:key="'servicesprices-' + index" class="pd-item pd-break">
                        <span class="pd-text">{{ service.name }}:</span>
                        <span class="pd-price">{{ service.staticPrice }} kr</span>
                    </div>
                    <div v-if="price.servicePriceState.data != null" class="pd-item">
                        <span class="pd-text">Totalsumma inkl. moms:</span>
                        <span class="pd-price pd-price-total">{{ formatPrice(totalPrice) }}</span>
                    </div>
                </div>

                <button class="ax-button ax-button-grey" style="background-color:white" @click="closeDetails">Stäng</button>
        </div>

    </div>
</div>

</template>

<script>

    import { mapState } from 'vuex';
    import { formatPrice } from '../utils/formatPrice';

    export default {
        name: 'priceDetailsUser',
        data() {
            return {
                open: false,
                formatPrice,
            };
        },
        computed: {
            ...mapState({
                price: state => state.price,
                priceDate: state => state.price.servicePriceState.data,
                texts: state => state.config.texts,
                totalPrice: state => state.price.totalPrice,
                hidePrice: state => state.config.disablePrices
            }),
            showHeaderAndBreaks: {
                get() {
                    return this.price.serviceCount > 1;
                }
            },
        },
        methods: {
            openDetails() {
                this.open = true;
                this.$emit('openDetails', true)
            },
            closeDetails() {
                this.open = false;
                this.$emit('openDetails', false)
            }
        },
    }

</script>