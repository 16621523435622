const state = {
    sessionUniqueId: 0,
    workshopId: '',
    serviceIds: ''
};

const getters = {
    defaultTimeout: (state) => {
        if (state.defaultTimeout != undefined && state.defaultTimeout>0) {
            return state.defaultTimeout;
        }
        return 10000;
    }
};

const actions = {
    load({ commit }) {
        const config = window.offertConfig;
        commit('configReceived', config);
/*        dispatch('services/setServices', config.services, { root: true });*/

    },
    setSessionUniqueId({ commit }) {
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        let value = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        commit('setSessionUniqueId', value);
    },
    trackAction({ commit }, value) {
        commit('trackAction', value);
    },
    updatePreselectedWorkshop({ commit }, value) {
        commit("updatePreselectedWorkshop", value);
    },
    updatePreselectedServices({ commit }, value) {
        commit("updatePreselectedServices", value);
    },
};

const mutations = {
    configReceived(state, config) {
        Object.assign(state, config);
    },
    updatePreselectedServices(state, value) {
        state.serviceIds = value;
    },
    setSessionUniqueId(state, value) {
        state.sessionUniqueId = value;
    },
    updatePreselectedWorkshop(state, value) {
        state.workshopId = value;
    },
    trackAction(state, value) {

        const gtmBaseArgs = {
            event: value.event,
            sessionUniqueId: state.sessionUniqueId,
        };

        let gtmArgs = {
            ...gtmBaseArgs,
            ...value.data
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(gtmArgs);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
