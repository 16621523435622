export default function localeDateString(date, time, montOption) {
    let dateString = new Date(date).toLocaleDateString('sv-SE',
        {
            weekday: 'short',
            month: montOption,
            day: 'numeric'
        });

    if (time == null)
        time = '08:00';

    if (time.length == 4)
        time = '0' + time;

    return dateString.charAt(0).toUpperCase() + dateString.slice(1) + ' ' + time;
}