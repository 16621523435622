<template>
    <div v-if="stepVisible" class="offer__modal-step-wrapper">
        <ModalWrapper :finalStep="true" :title="title" :nextLabel="'Stäng'" @onNextStep="closeOffer">
            <div class="offer__thanks-step">
                <div class="offer__thanks-step__image-container">
                    <img src="../assets/AX-kortlogo-rgb.png" />
                    <div class="offer__thanks-step__image-icon">
                        <i class="fal fa-check"></i>
                    </div>
                </div>
                <div class="offer__thanks-step__title">
                    {{stepTitle}}
                </div>
                <div class="offer__thanks-step__text">
                    {{thanksStep}}
                </div>
            </div>
        </ModalWrapper>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex';
    import steps from '../constants/steps';
    import ModalWrapper from './ModalWrapper.vue';

    export default {
        name: 'thanksStep',
        components: {
            ModalWrapper,
        },
        data() {
            return {
                title: '',
                thanksText: '',
                stepTitle: '',
            };
        },
        computed: {
            ...mapState({
                stepProgress: state => state.stepProgress,
                price: state => state.price,
                allwaysAllowBooking: state => state.config.allwaysAllowBooking,
            }),
            stepVisible: {
                get() {
                    return (this.stepProgress.currentStep == steps.thanksStep);
                },
            },
        },
        watch: {
            stepVisible(visible) {
                if (visible) {
                    if (this.allwaysAllowBooking || (this.price.isPriceRequest && this.price.priceLoaded && !this.price.priceError)) {
                        this.title = 'Bokning slutförd';
                        this.stepTitle = 'Tack för att du valde Autoexperten, längre leve din bil!';
                        this.thanksStep = 'Ett bekräftelsemail har skickats till dig med all information du behöver inför ditt besök hos verkstaden.'
                    } else {
                        this.title = 'Förfrågan skickad';
                        this.stepTitle = 'Tack för din förfrågan hos Autoexperten.';
                        this.thanksStep = 'Ett bekräftelsemail har skickats till dig med information om din förfrågan. Vi återkommer till dig via mail inom kort'
                    }
                }
            },
        },
        methods: {
            ...mapActions({
                switchStep: 'stepProgress/switchToStep',
            }),
            closeOffer() {
            },
        },
    }

</script>