import RequestStates from "../../constants/RequestStates";

const defaultValue = () => ({
    servicePriceState: {
        status: null,
        data: null,
        error: null
    },
    workshopServices: {
        status: null,
        data: null,
        error: null,
    },
    selectedDate: null,
    selectedTime: null,
    isPriceRequest: false,
    priceError: false,
    priceLoaded: false,
    priceApiVersion: null,
    totalPrice: 0,
    servicePrices: [],
    partPayment: {
        fee: 0,
        feeMonths: 0,
    },
    serviceCount: 0
});

const state = defaultValue();
const getters = {};

const actions = {
    pickWorkshopServices({ commit }, value) {
        commit("pickWorkshopServices", value);
    },
    pickServicePriceState({ commit }, value) {
        commit("pickServicePriceState", value);
    },
    pickSelectedDate({ commit }, value) {
        commit("pickSelectedDate", value);
    },
    isPriceRequest({ commit }, value) {
        commit("isPriceRequest", value);
    },
    checkPriceStates({ commit, value }) {
        commit("checkPriceStates", value);
    },
    resetPrice({ commit }) {
        commit("resetPrice", defaultValue());
    },
    updatePartPayment({ commit, value }) {
        commit("updatePartPayment", value);
    },
    updateApiVersion({ commit, value }) {
        commit("updateApiVersion", value);
    },
}
const mutations = {
    pickWorkshopServices(state, value) {
        state.workshopServices = Object.assign({}, value);
    },
    pickServicePriceState(state, value) {
        state.servicePriceState = Object.assign({}, value);
    },
    pickSelectedDate(state, value) {
        state.selectedDate = value.date;
        state.selectedTime = value.time;
    },
    isPriceRequest(state, value) {
        state.isPriceRequest = value;
    },
    checkPriceStates(state, selectedServicesIds) {
        state.priceError = false;
        state.totalPrice = 0;
        state.serviceCount = 0;

        if (helpers.checkPriceState(state, state.workshopServices.status) && helpers.checkPriceState(state, state.servicePriceState.status)) {
            state.priceLoaded = true;
        }
        helpers.checkTotalPrice(state, selectedServicesIds);
    },
    resetPrice(state, value) {
        state.workshopServices = Object.assign({}, value.servicePriceState);
        state.servicePriceState = Object.assign({}, value.servicePriceState);
        state.selectedDate = value.selectedDate;
        state.selectedTime = value.selectedDate;
        state.isPriceRequest = value.isPriceRequest;
        state.priceError = value.priceError;
        state.priceLoaded = value.priceLoaded;
        state.totalPrice = value.totalPrice;
        state.servicePrices = value.servicePrices;
        state.priceApiVersion = null;
    },
    updatePartPayment(state, value) {
        state.partPayment.fee = value.fee;
        state.partPayment.feeMonths = value.month;
    },
    updateApiVersion(state, value) {
        state.priceApiVersion = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
var helpers = {
    checkPriceState(state, status) {
        if (status == null || status == RequestStates.Success)
            return true;
        if (status == RequestStates.Failure)
            state.priceError = true;
        return false;
    },
    checkTotalPrice(state, selectedServicesIds) {

        state.totalPrice = 0;
        state.servicePrices = [];
        if (state.priceLoaded && state.isPriceRequest) {

            if (state.servicePriceState.data != null ) {
                if (state.servicePriceState.data.priceInfo != null && state.servicePriceState.data.priceInfo.isValid) {
                    state.totalPrice = state.servicePriceState.data.priceInfo.isDiscount ? state.servicePriceState.data.priceInfo.totalCostWithDiscount : state.servicePriceState.data.priceInfo.totalCost;
                    state.serviceCount++;
                }

                state.servicePriceState.data.simplePriceInfos.forEach(s => {
                    state.totalPrice += (s.isDiscount ? s.sumTotalWithDiscountVat : s.sumTotalVat);
                    state.serviceCount++;
                })

            }

            if (state.workshopServices.status == RequestStates.Success) {
                selectedServicesIds.forEach((id) => {
                    state.workshopServices.data.forEach((s) => {
                        if (s.serviceID == id) {
                            if (!s.isFixedPriceAvailable && s.staticPrice > 0) {
                                state.servicePrices.push(s);
                                state.totalPrice += s.staticPrice;
                                state.serviceCount++;
                            }
                            else if (!s.isFixedPriceAvailable) {
                                state.priceError = true;
                            }
                        }
                    });
                });
            }

            if (state.priceError)
                state.totalPrice = 0;         
        }
    }
};