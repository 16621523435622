const defaultValue = () => ({
    pickWorkshopState: {
        status: null,
        data: null,
        error: null,
    },
    selectedWorkshopId: 0,
    selectedWorkshop: null
});
const state = defaultValue();
const getters = {};

const actions = {
    pickWorkshopState({ commit }, value) {
        commit("pickWorkshopState", value);
    },
    updateSelectedWorkshopIdState({ commit }, value) {
        commit("updateSelectedWorkshopState", value);
    },
    reset({ commit }) {
        commit("reset");
    },
}
const mutations = {
    pickWorkshopState(state, value) {
        state.pickWorkshopState = Object.assign({}, value);
    },
    updateSelectedWorkshopState(state, value) {
        state.selectedWorkshopId = value.workshopId;
        state.selectedWorkshop = value.workshop;
    },
    reset(state) {
        const resetValue = defaultValue();
        state.pickWorkshopState = Object.assign({}, resetValue.pickWorkshopState);
        state.selectedWorkshopId = resetValue.workshopId;
        state.selectedWorkshop = resetValue.workshop;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};